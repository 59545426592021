<!-- <div class="d-flex my-3 pb-1" *ngIf="showEkcOption">
  <button
    mat-raised-button
    (click)="connectToEKC()"
    class="btn btn-connect btn-connect-azure w-100 mr-2"
    data-qa-id="azure"
    type="submit">
    <img width="48px" height="40px" class="float-left pt-1 pl-1" src="assets/img/icons/azure-logo-icon.svg" />
    USE AZURE
  </button>
  <button
    mat-button
    class="btn btn-connect btn-connect-azure"
    data-qa-id="edit-azure-settings"
    (click)="editEKCSettings()">
    <span class="icon-settings"></span>
  </button>
</div>
<div class="my-3">
  <button
    mat-raised-button
    (click)="connectToWalletConnect()"
    data-qa-id="mobile-wallet"
    class="btn btn-connect btn-connect-wallet w-100"
    type="submit">
    <img width="48px" height="40px" class="float-left pt-1 pl-1" src="assets/img/icons/wallet-connect-icon.svg" />
    USE MOBILE WALLET
  </button>
</div> -->
<div class="mt-3 mb-3" *ngIf="metamaskPresent">
  <button mat-raised-button (click)="connectToMetamask()" data-qa-id="metamask" [disabled]="metamaskDisabled"
    class="btn btn-connect btn-connect-metamask w-100" type="submit">
    <img width="48px" height="40px" class="float-left pt-1 ml-1" src="assets/img/icons/logo-kilt.svg" />
    <ng-container i18n="@@loginBtnText">Login With KILT</ng-container>
  </button>
  <div class="d-flex flex-column justify-content-center mt-1 text-center" *ngIf="metamaskDisabled"
    data-qa-id="wrong-network">
    <div class="opacity-50 pr-1" i18n="@@notConnectedNetwork">You are not connected to {{ fullNetworkName }}.</div>
    <a class="switch-network" (click)="importMetamaskConf()" target="_blank" i18n="@@importConfigurations"> Import
      configurations </a>
  </div>
</div>
<div class="d-flex flex-column justify-content-center mt-1 text-center" *ngIf="!metamaskPresent"
  data-qa-id="wrong-network">
  <div class="opacity-50 pr-1" i18n="@@installMetamaskExtension">Please install metamask extension.</div>
  <a class="switch-network" (click)="installMetamask()" target="_blank" i18n="@@installHere"> Install here </a>
</div>