<div class="card card-hint d-flex flex-row justify-content-start align-items-center shadow-none d-flex pt-3 pb-2 mb-3">
  <mat-icon>notifications_none</mat-icon>
  <span class="pb-2" i18n="@@txComplete.TxCompletedMessage">All transactions are completely processed.</span>
</div>
<div>
  <div class="d-flex justify-content-end mb-2">
    <button mat-raised-button class="btn btn-small btn-primary" data-qa-id="complete" (click)="completed.emit()"
      i18n="@@txComplete.Btn.Complete">
      Complete
    </button>
  </div>
</div>