<mat-form-field class="md-search" appearance="outline">
  <input type="text" data-qa-id="smart-search-input" [placeholder]="placeholderSearch" matInput
    [formControl]="searchText" [matAutocomplete]="auto" (input)="updateSearchTxtFieldValue()"
    (keyup.enter)="selectionHandler()" />

  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
    (optionSelected)="autocompleteSelectionHandler($event)">
    <mat-option *ngFor="let item of filteredOptions | async" style="height: auto" [value]="item.namespace"
      class="d-flex align-items-start">
      <app-smart-search-option [namespace]="item.namespace" [definition]="$any(item?.definition)"></app-smart-search-option>
    </mat-option>
  </mat-autocomplete>
  <div matSuffix class="d-flex" *ngIf="showButtons()">
    <ng-container *ngIf="!isLoadingList">
      <button mat-icon-button (click)="search()" class="search-button" data-qa-id="search">
        <mat-icon>search</mat-icon>
      </button>
      <button *ngIf="isAdding" [disabled]="!searchText.value" mat-icon-button data-qa-id="add" (click)="addSelection()">
        <mat-icon>add</mat-icon>
      </button>
      <button *ngIf="isDefault" mat-icon-button (click)="clear()" data-qa-id="clear">
        <mat-icon>close</mat-icon>
      </button>
    </ng-container>
    <mat-spinner *ngIf="isLoadingList" diameter="21" class="default-color"></mat-spinner>
  </div>
  <ng-content></ng-content>
</mat-form-field>