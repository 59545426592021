<form role="form" [formGroup]="enrolmentForm">
  <ng-container *ngIf="fieldList.length">
    <div class="row">
      <!-- START: Dynamic Form Fields -->
      <ng-container *ngFor="let field of enrolmentForm?.get('fields')['controls']; let i = index">
        <div [ngClass]="{
            'col-lg-12':
              fieldList[i].fieldType === 'text' ||
              fieldList[i].fieldType === 'boolean' ||
              fieldList[i].fieldType === 'json',
            'col-lg-6': fieldList[i].fieldType === 'number' || fieldList[i].fieldType === 'date'
          }">
          <mat-label [ngStyle]="txtColor" data-qa-id="label" class="ml-3 pl-1"
            *ngIf="fieldList[i].fieldType !== 'boolean'">
            {{ fieldList[i].label }}
            {{ fieldList[i].required ? '' : '(Optional)' }}
          </mat-label>

          <!-- TYPE: Text -->
          <mat-form-field class="mt-2 md-enroll" appearance="outline" floatPlaceholder="never"
            *ngIf="fieldList[i].fieldType === 'text'" [attr.data-qa-id]="'field-' + i" [ngStyle]="txtColor">
            <input matInput type="text" placeholder="{{ fieldList[i].label }}" [formControl]="getControl(i)" />
            <mat-error *ngIf="getControl(i)?.errors?.required" i18n="@@register.enrol.field.required"> This field is
              required.</mat-error>
            <mat-error *ngIf="getControl(i)?.errors?.minlength">
              Please input at least {{ fieldList[i].minLength }} characters.
            </mat-error>
            <mat-error *ngIf="getControl(i)?.errors?.maxlength">
              Please input at most {{ fieldList[i].maxLength }} characters.
            </mat-error>
            <mat-error *ngIf="getControl(i)?.errors?.pattern" i18n="@@register.enrol.incorrectDataFormat"> Incorrect
              data format.</mat-error>
          </mat-form-field>


          <!-- Button -->
          <button button mat-raised-button class="btn btn-primary md-enroll w-100" appQrCodeScanner
            data-qa-id="enrol-form-qr-code-scanner" i18n="@@register.requestClaim.fieldBtn.scanQRcode"
            (scannedValue)="scannedData($event.data)">
            Scan QR Code
          </button>

          <!-- TYPE: Number -->
          <mat-form-field class="mt-2 md-enroll" appearance="outline" floatPlaceholder="never"
            *ngIf="fieldList[i].fieldType === 'number'" [attr.data-qa-id]="'field-' + i" [ngStyle]="txtColor">
            <input matInput type="number" placeholder="{{ fieldList[i].label }}" [formControl]="getControl(i)" />
            <mat-error *ngIf="getControl(i)?.errors?.required"> This field is required.</mat-error>
            <mat-error *ngIf="getControl(i)?.errors?.max"> Max value is {{ fieldList[i].maxValue }}.</mat-error>
            <mat-error *ngIf="getControl(i)?.errors?.min"> Min value is {{ fieldList[i].minValue }}.</mat-error>
          </mat-form-field>

          <!-- TYPE: Json -->
          <app-json-editor class="mt-2 md-enroll" *ngIf="fieldList[i].fieldType === 'json'"
            [attr.data-qa-id]="'field-' + i" [options]="createOptions(fieldList[i].schema)"
            (change)="checkJson($event, fieldList[i].schema)" [formControl]="getControl(i)" [ngStyle]="txtColor">
            <mat-error *ngIf="getControl(i)?.errors?.required" i18n="@@register.enrol.field.required"> This field is
              required.</mat-error>
            <mat-error *ngIf="!isValidSchema" i18n="@@register.enrol.propertyNotFitSchema">Properties do not fits
              schema.</mat-error>
          </app-json-editor>

          <!-- TYPE: Date -->
          <mat-form-field class="mt-2 md-enroll bg-transparent" appearance="outline" floatPlaceholder="never"
            *ngIf="fieldList[i].fieldType === 'date'" [attr.data-qa-id]="'field-' + i" [ngStyle]="txtColor">
            <input [matDatepicker]="picker" [max]="fieldList[i].maxDate" [min]="fieldList[i].minDate" matInput
              placeholder="{{ fieldList[i].label }}" autocomplete="off" [formControl]="getControl(i)" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="getControl(i)?.errors?.required" i18n="@@register.enrol.field.required"> This field is
              required.</mat-error>
            <mat-error *ngIf="getControl(i)?.hasError('matDatepickerMin')">
              Minimum allowed date is {{ fieldList[i].minDate | date }}
            </mat-error>
            <mat-error *ngIf="getControl(i)?.hasError('matDatepickerMax')">
              Maximum allowed date is {{ fieldList[i].maxDate | date }}
            </mat-error>
          </mat-form-field>

          <!-- TYPE: Boolean -->
          <mat-checkbox class="pl-3 md-enroll" *ngIf="fieldList[i].fieldType === 'boolean'"
            [attr.data-qa-id]="'field-' + i" [formControl]="getControl(i)" [ngStyle]="txtboxColor">
            <span class="mb-3 d-inline-flex" data-qa-id="label" [ngStyle]="txtColor">
              {{ fieldList[i].label }}
              {{ fieldList[i].required ? '' : '(Optional)' }}
            </span>
          </mat-checkbox>
        </div>
      </ng-container>
      <!-- END: Dynamic Form Fields -->
    </div>
  </ng-container>

  <div class="mt-3" *ngIf="showSubmit">
    <button mat-raised-button data-qa-id="submit-request" class="btn btn-primary md-enroll w-100" type="submit"
      (click)="submit()" [disabled]="disabledSubmit || enrolmentForm.invalid || !isValidSchema" [ngStyle]="btnColor"
      i18n="@@register.enrol.btn.submit">
      Submit Request
    </button>
  </div>
</form>